<template>
    <div class="container">
        <center>
            <h3 class="title-seccion">Restaurar Contrase&ntilde;a</h3>
        </center>
        <br>
        <form v-on:submit.prevent="recuperarPassword" method="post" class=" row justify-content-md-center">
            <div class="col-md-6 col-12 dialog-1  row justify-content-md-center"
                style="margin-left: 0px !important;margin-right: 0px !important;">
                <div class="form-group col-12">
                    <div class="col-12">
                        <label for="correo" class="form-label informacion">Correo Electr&oacute;nico<i
                                class="text-danger">*</i></label>
                    </div>
                    <div class="col-12">
                        <input id="correo" v-model="form.correo" type="email" class="form-control" name="correo"
                            v-on:input="validatingEmail"
                            v-bind:class="{ 'input-correcto': validations.isValidoEmail, 'input-incorrecto': NoValidations.isInvalidoEmail }"
                            value="" required autofocus>
                    </div>
                </div>
                <center>
                    <div class="form-group col-md-6 col-12">
                        <div class="col-12">
                            <button :disabled="showButtonSubmit" type="submit" id="btnrecuperarcontra"
                                class=" btn-success btn-block btn justify-content-center">Enviar correo</button>
                        </div>
                        <div class="col-12">
                            <a menu-option="iniciarSesion" hide-banners="true" v-on:click="this.$parent.changeMenuPrincipal"
                                class="btn btn-link col-form-label">Iniciar
                                sesi&oacute;n
                            </a>
                            <br>
                            <a menu-option="cambiarContrasena" hide-banners="true" v-on:click="this.$parent.changeMenuPrincipal"
                                class="btn btn-link col-form-label">Ya tengo mi token
                            </a>
                        </div>
                    </div>
                </center>
            </div>
        </form>
    </div>
</template>


<script>
import axios from 'axios';
export default {
    name: 'recuperarContrasena',
    data() {
        return {
            form: {
                correo: '',
            }, validations: {
                isValidoEmail: false,
            }, NoValidations: {
                isInvalidoEmail: false,
            }
        }
    }, methods: {
        recuperarPassword() {
            this.$parent.loadingModalPrincipal("Enviando correo de recuperaci&oacute;n...");
            axios.post(this.$endPointNewton + "/stock-push/registro-usuarios:recuperarcontrasena", this.form
            ).then((res) => {
                if (res.data.status === 'fail') {
                    this.$parent.ErrorModalPrincipal(res.message);
                } else {
                    this.$swal({
                        title: "Correo de recuperación enviado exitosamente.",
                        closeOnClickOutside: false,
                        value: true,
                        text: "Si no localizas el correo, revisa tus correos de SPAM.",
                        visible: true,
                        icon: "success",
                        confirmButtonText: "Continuar",
                    });
                }
            }).catch(() => {
                this.$parent.ErrorModalPrincipal('El correo que ingresaste no esta registrado.');
            });
        }, validatingEmail(event) {
            if (this.$parent.validateEmailPrincipal(event.target.value)) {
                this.validations.isValidoEmail = true;
                this.NoValidations.isInvalidoEmail = false;
            } else {
                this.validations.isValidoEmail = false;
                this.NoValidations.isInvalidoEmail = true;

            }
        }
    }, computed: {
        showButtonSubmit: function () {
            var validated = false;
            for (const prop in this.validations) {
                if (!this.validations[prop]) {
                    validated = true;
                }
            }
            return validated;
        }

    }
}

</script>