import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import "./assets/css/style.css";
import "bootstrap/js/src/dropdown";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import bigdecimal from 'bigdecimal/lib/bigdecimal.js'
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(bigdecimal);
Vue.use(VueSweetalert2);
//DESARROLLO LOCAL 
//Vue.prototype.$endPointNewton = 'http://localhost:8000'
//Vue.prototype.$endPointKepler = 'http://localhost:8090'
//PRODUCCION
//Vue.prototype.$endPointNewton = 'https://themisto.klayware.com'
//Vue.prototype.$endPointKepler = 'https://elara.klayware.com'

Vue.config.productionTip = false
Vue.prototype.$endPointNewton = 'https://themisto.klayware.com'
Vue.prototype.$endPointKepler = 'https://elara.klayware.com'
Vue.prototype.$keyRecaptchaPublic = '6LeWKyokAAAAAA_NjeFm9nRoGmraeVQqI1bwb_Ty'
Vue.prototype.$keyConektaPublic = 'key_TKphuzUwXqCbYAaLOGEdypA'
//pruebas key_GL4s3s5XxEAB3TE9icDlHAk
//produccion key_TKphuzUwXqCbYAaLOGEdypA

new Vue({
  render: h => h(App),
}).$mount('#app')
