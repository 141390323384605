<template>
    <div class="container">
        <center>
            <h3 class="title-seccion">Iniciar sesi&oacute;n</h3>
        </center>
        <form v-on:submit.prevent="iniciarSesionKey" method="post" class=" row justify-content-md-center">
            <div class="col-md-6 col-12 dialog-1  row justify-content-md-center"
                style="margin-left: 0px !important;margin-right: 0px !important;">
                <div class="form-group col-12">
                    <div class="col-12">
                        <label for="correo" class=" col-sm-4 col-form-label text-md-right">Correo
                            Electr&oacute;nico<i class="text-danger">*</i></label>
                    </div>
                    <div class="col-md-12">
                        <input id="correo" v-model="form.correo" type="email" class="form-control" name="correo"
                            v-on:input="validatingEmail"
                            v-bind:class="{ 'input-correcto': validations.isValidoEmail, 'input-incorrecto': NoValidations.isInvalidoEmail }"
                            value="" required autofocus>
                    </div>
                </div>
                <div></div>
                <div class="form-group col-12">
                    <div class="col-12">
                        <label for="contrasena" class="col-md-4 col-form-label text-md-right">Contraseña<i
                                class="text-danger">*</i></label>
                    </div>
                    <div class="col-12">
                        <input v-model="form.contrasena" id="contrasena" type="password" class="form-control"
                            name="contrasena" required>
                    </div>
                </div>
                <div></div>
                <div class="form-group col-12">
                </div>
                <div></div>
                <div class="form-group col-md-6 col-12">
                    <div class="col-12">
                        <button :disabled="showButtonSubmit" type="submit" class="btn btn-success">
                            Ingresar
                        </button>
                    </div>
                    <div class="form-group col-12">
                        <center>
                            <a class="btn btn-link col-form-label" menu-option="recuperarContrasena" hide-banners="true"
                                v-on:click="this.$parent.changeMenuPrincipal">
                                ¿Olvidaste tu contrase&ntilde;a?
                            </a>
                        </center>
                    </div>
                    <div class="form-group col-12 ">
                        <center>
                            <a menu-option="registro" hide-banners="true" v-on:click="this.$parent.changeMenuPrincipal"
                                type="button" class="col-form-label btn btn-primary">Crear
                                cuenta nueva</a>
                        </center>
                    </div>
                    <br>
                </div>
            </div>
        </form>
    </div>
</template>


<script>
import axios from 'axios';
export default {
    name: 'iniciarSesion',
    data() {
        return {
            form: {
                correo: '',
                contrasena: ''
            }, validations: {
                isValidoEmail: false,
            }, NoValidations: {
                isInvalidoEmail: false,
            }
        }
    }, methods: {
        iniciarSesionKey() {
            this.$parent.loadingModalPrincipal("Iniciando sesi&oacute;n...");
            axios.post(this.$endPointNewton + "/stock-push/login:obtener_apikey", this.form
            ).then((res) => {
                var dataInicioSesion = { 'correo': this.form.correo, 'apikey': res.data.data.apikey };
                this.iniciarSesion(dataInicioSesion);
            })
                .catch(() => {
                    this.$parent.ErrorModalPrincipal('Usuario o contraseña invalidos');
                });
        }, iniciarSesion(dataInicioSesion) {
            axios.post(this.$endPointNewton + "/stock-push/Sesion:iniciar", dataInicioSesion
            ).then((res) => {
                if (res.data.status === 'fail') {
                    this.$parent.ErrorModalPrincipal(res.message);
                } else {
                    var token = res.data.data.jwt;
                    var rol = res.data.data.usuario.roles['0'];
                    var componentToload = 'indexUsuario';
                    sessionStorage.setItem('user', JSON.stringify(res.data.data.usuario));
                    sessionStorage.setItem('token', token);
                    sessionStorage.setItem('rol', rol);
                    if (rol === "administrador") {
                        componentToload = "indexAdmin"
                    } else {
                        if (document.getElementById("li-sesion").getAttribute("plan-contratar") !== 0 || typeof document.getElementById("li-sesion").getAttribute("plan-contratar") !== "undefined") {
                            sessionStorage.setItem('plan-contratar', document.getElementById("li-sesion").getAttribute("plan-contratar"));
                        }
                    }
                    this.$parent.SuccessModalPrincipal("Iniciaste sesi&oacute;n exitosamente.", true, 'Continuar', componentToload);
                }
            })
                .catch(() => {
                    this.$parent.ErrorModalPrincipal('Ocurrió un error al iniciar sesió n');
                });

        }, validatingEmail(event) {
            if (this.$parent.validateEmailPrincipal(event.target.value)) {
                this.validations.isValidoEmail = true;
                this.NoValidations.isInvalidoEmail = false;
            } else {
                this.validations.isValidoEmail = false;
                this.NoValidations.isInvalidoEmail = true;

            }
        }

    }, computed: {
        showButtonSubmit: function () {
            var validated = false;
            for (const prop in this.validations) {
                if (!this.validations[prop]) {
                    validated = true;
                }
            }
            return validated;
        }

    }
}

</script>