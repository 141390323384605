<template>
    <div v-if="this.$parent.verifySesion" class="bodyusu">
        <headerUsuario></headerUsuario>
        <div class="container pb-5">
            <div class="row pb-5 no-margin-row">
                <titleOptions :title="this.opciones"></titleOptions>
                <div class="background-grey row">
                    <opcionesComponent :show=this.showOptions :menuOpciones="this.opciones"></opcionesComponent>
                    <component class="bg-white rounded" :is="current" :menuOpciones="this.opciones" :dataForComponent="this.dataForComponent" :pagoType="this.pagoType" :showOpt ="this.showOptions"></component>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import headerUsuario from "../Usuario/headerUsuario.vue"
import opcionesComponent from "../Usuario/opcionesComponent.vue"
import titleOptions from "../Usuario/titleOptions.vue"
export default {
    data() {
        return {
            opciones: "Empresas",
            current: "listado",
            dataForComponent:"",
            showOptions : "true",
            pagoType : ""
        }
    }, components: {
        headerUsuario,
        opcionesComponent,
        titleOptions,
        'listado': () => import('../commons/listado.vue'),
        'add': () => import('../commons/add.vue'),
        'edit' : () => import('../commons/edit.vue'),
        'contratacion' : () => import('../commons/contratacion.vue'),
    }, methods: {
        changeComponent(nameC , dataForComponent,optUser,showOpt , pago) {
            if (typeof dataForComponent !== 'undefined'){
                this.dataForComponent=dataForComponent;
            }
            if (typeof optUser !== 'undefined'){
                this.opciones = optUser;
            }
            if (typeof pago !== 'undefined'){
                this.pagoType = pago;
            }
            this.current = nameC;
            this.showOptions = showOpt;

        }
    }, mounted (){
        if ( typeof sessionStorage.getItem('plan-contratar') !== "undefined" && sessionStorage.getItem('plan-contratar') > 0) {
           this.opciones = "Contratación";
           this.showOptions = "false";
           this.current = "contratacion";
            this.dataForComponent = {"planId" : sessionStorage.getItem('plan-contratar')};
        }

    }
}
</script>