<template>
    <div id="contacto">
        <div class="container pb-5">
            <center>
                <h3 class="title-seccion">Contacto</h3>
            </center>
            <br>
            <form class=" row justify-content-md-center" v-on:submit.prevent="registrar">
                <div class="col-md-6 col-12 dialog-1  row justify-content-md-center"
                    style="margin-left: 0px !important;margin-right: 0px !important;">
                    <div class="mb-3">
                        <label for="nombre" class="form-label informacion">Nombre<i class="text-danger">*</i></label>
                        <input v-model="form.nombre" v-on:keyup="validarTexto" class="form-control" id="nombre"
                            v-bind:class="{ 'input-correcto': validations.isValidoNombre, 'input-incorrecto': NoValidations.isInvalidoNombre }"
                            name="nombre" required>
                    </div>
                    <div class="mb-3">
                        <label for="apellidos" class="form-label informacion">Apellidos<i class="text-danger">*</i></label>
                        <input v-model="form.apellidos" v-on:keyup="validarTexto" type="text" class="form-control"
                            id="apellidos" name="apellidos"
                            v-bind:class="{ 'input-correcto': validations.isValidoApellido, 'input-incorrecto': NoValidations.isInvalidoApellido }"
                            required>
                    </div>
                    <div class="mb-3">
                        <label for="empresa" class="form-label informacion">Empresa/Negocio<i
                                class="text-danger">*</i></label>
                        <input v-model="form.empresa" type="text" name="empresa" class="form-control" id="empresa" required>
                    </div>
                    <div class="mb-3">
                        <label for="phonenumber" class="form-label informacion">Tel&eacute;fono<i
                                class="text-danger">*</i></label>
                        <input v-model="form.telefono" maxlength="10" v-on:input="validateNumberPhone" type="text"
                            class="form-control" id="telefono" name="telefono"
                            v-bind:class="{ 'input-correcto': validations.isValidoNumber, 'input-incorrecto': NoValidations.isInvalidoNumber }"
                            required>
                    </div>
                    <div class="mb-3">
                        <label for="email" class="form-label informacion">Correo<i class="text-danger">*</i></label>
                        <input v-model="form.correo" type="email" v-on:input="validatingEmail" class="form-control"
                            id="correo" name="correo"
                            v-bind:class="{ 'input-correcto': validations.isValidoEmail, 'input-incorrecto': NoValidations.isInvalidoEmail }"
                            required>
                    </div>
                    <div class="mb-3">
                        <label for="mensaje" class="form-label informacion">Comentarios</label>
                        <textarea class="form-control" id="mensaje" v-model="form.comentarios" rows="6"
                            maxlength="400"></textarea>
                    </div>
                    <div class=" row col-12 no-margin">
                        <div class="col-12 no-padding"> <input v-model="validations.check" class="form-check-input"
                                type="checkbox" value="" id="invalidCheck" required>
                            <label class="form-check-label" for="invalidCheck">
                                Acepto los t&eacute;rminos y condiciones
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6 col-12 ">
                        <button :disabled="showButtonSubmit" type="submit" id="btncontacto"
                            class=" btn-success btn-block btn justify-content-center">Enviar</button>
                    </div>

                </div>
            </form>
        </div>
    </div>
</template>
    
<script>
import Vue from "vue";
import axios from 'axios';
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: "6LeWKyokAAAAAA_NjeFm9nRoGmraeVQqI1bwb_Ty" });

export default {
    name: 'contacto',
    data() {
        return {
            form: {
                nombre: '',
                apellidos: '',
                telefono: '',
                correo: '',
                empresa: '',
                comentarios: '',
            },
            validations: {
                check: false,
                isValidoNombre: false,
                isValidoApellido: false,
                isValidoNumber: false,
                isValidoEmail: false,
            }, NoValidations: {
                isInvalidoNombre: false,
                isInvalidoApellido: false,
                isInvalidoNumber: false,
                isInvalidoEmail: false,
            },
        }
    }, methods: {
        registrar() {

            this.$parent.loadingModalPrincipal("Enviando informaci&oacute;n...");
            var nombre = this.form.nombre;
            var apellidos = this.form.apellidos;
            var empresa = this.form.empresa;
            var telefono = this.form.telefono;
            var correo = this.form.correo;
            var comentarios = this.form.comentarios;
            var data = { "nombre": nombre, "apellidos": apellidos, "empresa": empresa, "telefono": telefono, "correo": correo, "comentarios": comentarios, "roles": ["cliente"], "credenciales": ["@action:*", "@schema:*", "@catalogo:*", "@flujo:*", "@documento:*"] }
            axios.post(this.$endPointNewton + "/stock-push/contacto:contacto", data
            ).then((res) => {
                if (res.data.status === "fail") {
                    this.$parent.ErrorModalPrincipal(res.message);
                } else {
                    this.cleanForm();
                    this.$parent.SuccessModalPrincipal("Informaci&oacute; de contacto enviada exitosamente. <br> tan pronto como podamos nos pondremos en contacto contigo.", true, 'Continuar',);
                }
            }).catch(() => {
                this.$parent.ErrorModalPrincipal('Ocurrió un error al enviar la información de contacto.');
            });
        }, validarTexto: function (event) {
            var input = event.target.getAttribute('id');
            var resultValid = this.$parent.validarTextoPrincipal(event);
            this.validatingResult(input, resultValid);
            this.subactiveBtnmit = true;
        }, validateNumberPhone: function (event) {
            this.$parent.validateNumberPrincipal(event);
            var validation = this.$parent.validateCelularPrincipal(event.target.value);
            this.validations.isValidoNumber = validation;
            this.NoValidations.isInvalidoNumber = !validation;
        }, validatingResult(input, result) {
            if (input === "apellidos") {
                this.validations.isValidoApellido = result;
                this.NoValidations.isInvalidoApellido = !result;

            } else if (input === "nombre") {
                this.validations.isValidoNombre = result;
                this.NoValidations.isInvalidoNombre = !result;
            }
        }, validatingEmail(event) {
            if (this.$parent.validateEmailPrincipal(event.target.value)) {
                this.validations.isValidoEmail = true;
                this.NoValidations.isInvalidoEmail = false;
            } else {
                this.validations.isValidoEmail = false;
                this.NoValidations.isInvalidoEmail = true;

            }
        }, cleanForm() {
            this.form.nombre = '';
            this.form.apellidos = '';
            this.form.telefono = '';
            this.form.correo = '';
            this.form.empresa = '';
            this.form.comentarios = '';
            this.validations.isValidoNombre = false;
            this.validations.isValidoApellido = false;
            this.validations.isValidoNumber = false;
            this.validations.isValidoEmail = false;
            this.NoValidations.isInvalidoNombre = false;
            this.NoValidations.isInvalidoApellido = false;
            this.NoValidations.isInvalidoNumber = false;
            this.NoValidations.isInvalidoEmail = false;

        }
    }, computed: {
        showButtonSubmit: function () {
            var validated = false;
            for (const prop in this.validations) {
                if (!this.validations[prop]) {
                    validated = true;
                }
            }
            return validated;
        }

    }
}

</script>