<template>
    <div class="submenu-opciones col-md-2 col-12" v-if='(this.show === "true")'>
        <div class="col-12 border-bottom">
            <h4>Opciones</h4>
        </div>
        <ul class="nav_list row no-margin">

            <li><a @click.prevent="changeTab" class="list_name informacion" menu-option="listado">
                    <i class ="fa-solid fa-list" @click.prevent="changeTab" menu-option="listado"></i>
                        Listado 
                </a>
            </li>
            <li>
                <a class="list_name informacion"  @click.prevent="changeTab" menu-option="add">
                    <i class="fa-solid fa-plus" @click.prevent="changeTab" menu-option="add"></i>
                        Agregar
                </a>
            </li>
        </ul>
        <input type="hidden" id="user">
    </div>

</template>

<script>

export default {
    props: {
        menuOpciones: {
            type: String,
            default: "Empresas"
        },show: {
            type: String,
            default: "true",
        }
        
    }, methods: {
        changeTab(event) {
            if (typeof event.target.getAttribute('menu-option') !== 'undefined') {
                this.$parent.changeComponent(event.target.getAttribute('menu-option'), "" , this.menuOpciones, "true");
            } else {
                console.log("el valor para llamar el componente es inv&aacute;lido.");
            }
        }
    }
};
</script>