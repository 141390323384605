<template>
  <div class="row">
    <div class="col-md-4 col-4">
      <b-button class="btn-nav" v-b-toggle.sidebar-1>
        <i class="fa-solid fa-bars"></i> </b-button>
    </div>
    <b-sidebar id="sidebar-1" v-bind:title="this.getUsuarioName" shadow>
      <div class="submenu submenu-top">
        <ul class="nav_list row no-margin">
         
          <li class="nav-item dropdown">
            <a class="list_name informacion" v-on:click="changeTab" menu-option="listado" opt-user="Banners" show-opt="true">
              <i class="fa-solid fa-panorama"></i>
              Banners
            </a>
          </li>
          <li>
            <a class="list_name informacion" v-on:click="changeTab" menu-option="listado" opt-user="Contratos"
              show-opt="false">
              <i class="fa-solid fa-file-contract"></i>
              Contratos
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="list_name informacion" v-on:click="changeTab" menu-option="listado" opt-user="Formas_de_pago" show-opt="true">
              <i class="fa-solid fa-credit-card"></i>
              Formas de pago
            </a>
          </li>
          <li>
            <a class="list_name informacion" v-on:click="changeTab" menu-option="listado" opt-user="Planes_publicitarios_contratados"
              show-opt="false">
              <i class="fa-solid fa-rocket"></i>
              Planes contratados
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="list_name informacion" v-on:click="changeTab" menu-option="listado" opt-user="Planes" show-opt="true">
              <i class="fa-solid fa-bullhorn"></i>
              Planes publicitarios
            </a>
          </li>
          <li>
            <a class="list_name informacion" v-on:click="changeTab" menu-option="listado" opt-user="Usuarios"
              show-opt="true">
              <i class="fa-solid fa-users"></i> Usuarios
            </a>
          </li>
          <li>
            <a class="list_name informacion" v-on:click="changeTab" menu-option="listado" opt-user="Empresas"
              show-opt="true">
              <i class="fa-solid fa-building"></i>
              Empresas
            </a>
          </li>
          <!--<li class="nav-item dropdown">
            <a class="list_name informacion" v-on:click="changeTab" menu-option="listado" opt-user="Facebook_ads" show-opt="true">
              <i class="fa-brands fa-facebook"></i>
              Facebook ads
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="list_name informacion" v-on:click="changeTab" menu-option="listado" opt-user="Google_ads" show-opt="true">
              <img class="fa-google"
                        src="../../assets/img/iconos/google-logo.png">
              Google ads
            </a>
          </li>-->
        </ul>
      </div>
      <div class="d-flex footer-menu-lateral">
        <div class="submenu">
          <ul class="nav_list row no-margin">
            <li>
              <a class="list_name informacion" v-on:click="changeTab" data="localStorage.getItem('user')"
                menu-option="edit" opt-user="Configuración" show-opt="false">
                <i class="fa-solid fa-gear"></i>
                Configuraci&oacute;n
              </a>
            </li>
            <li class="nav-item dropdown">
              <a v-on:click="this.$parent.$parent.cerrarSesionModal" class="list_name informacion">
                <i class="fa-solid fa-user"></i>
                Cerrar sesi&oacute;n
              </a>
            </li>
          </ul>
        </div>
      </div>
    </b-sidebar>
    <div class="col-md-4 col-4 row ">
      <center>
        <i class="bi bi-0-circle"></i>
        <div class="col-12"><img class="logo-menu" src="../../assets/img/header/logo-stockpush.png"></div>
      </center>
    </div>

  </div>
</template>



<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    changeTab(event) {
      if (typeof event.target.getAttribute('menu-option') !== 'undefined') {
        this.$parent.changeComponent(event.target.getAttribute('menu-option'), "", event.target.getAttribute('opt-user'), event.target.getAttribute('show-opt'));
      } else {
        console.log("el valor para llamar el componente es inv&aacute;lido.");
      }
    }
  }, computed: {
    getUsuarioName: function () {
      var user = JSON.parse(sessionStorage.getItem("user"));
      if (typeof user.correo !== "undefined" && user.correo !== null) {
        return user.correo;
      }
      return "opciones de administradorW";
    }
  }
}
</script>
  
  
  

