<template>
    <div class="container">
        <center>
            <h3 class="title-seccion">Restaurar Contrase&ntilde;a</h3>
        </center>
        <br>
        <form v-on:submit.prevent="recuperarPassword" method="post" class=" row justify-content-md-center">
            <div class="col-md-6 col-12 dialog-1  row justify-content-md-center"
                style="margin-left: 0px !important;margin-right: 0px !important;">
                <div class="form-group col-12">
                    <div class="col-12">
                        <label for="correo" class="form-label informacion">Correo Electr&oacute;nico<i
                                class="text-danger">*</i></label>
                    </div>
                    <div class="col-12">
                        <input id="correo" v-model="form.correo" type="email" class="form-control" name="correo"
                            v-on:input="validatingEmail"
                            v-bind:class="{ 'input-correcto': validations.isValidoEmail, 'input-incorrecto': NoValidations.isInvalidoEmail }"
                            value="" required autofocus>
                    </div>
                </div>
                <div class="form-group col-12">
                    <div class="col-12">
                        <label for="correo" class="form-label informacion">Token de recuperaci&oacute;n<i
                                class="text-danger">*</i></label>
                    </div>
                    <div class="col-12">
                        <input id="token" v-model="form.token_recuperar" type="text" class="form-control" name="token"
                            v-on:input="validateToken"
                            v-bind:class="{ 'input-correcto': validations.isValidoToken, 'input-incorrecto': NoValidations.isInvalidoToken }"
                            value="" required autofocus>
                    </div>
                </div>
                <div class="form-group col-12">
                    <div class="col-12">
                        <label for="correo" class="form-label informacion">Nueva contraseña<i
                                class="text-danger">*</i></label>
                    </div>
                    <div class="col-12">
                        <input id="contrasena" v-model="form.contrasena_nueva" type="password" class="form-control"
                            name="contrasena" v-on:input="validatePasswords"
                            v-bind:class="{ 'input-correcto': validations.isValidoContrasena, 'input-incorrecto': NoValidations.isInvalidoContrasena }"
                            value="" required autofocus>
                    </div>
                </div>
                <div class="form-group col-12">
                    <div class="col-md-12">
                        <label for="passwordConfirmation" class="col-form-label text-md-right">Confirmar
                            contraseña<i class="text-danger">*</i></label>
                    </div>
                    <div class="col-md-12">
                        <input v-model="form.passwordConfirmation" type="password" class="form-control"
                            v-on:input="validatePasswords"
                            v-bind:class="{ 'input-correcto': validations.isValidoContrasena, 'input-incorrecto': NoValidations.isInvalidoContrasena }"
                            id="passwordConfirmation" name="passwordConfirmation" required>
                    </div>
                </div>
                <center>
                    <div class="form-group col-md-6 col-12">
                        <div class="col-12">
                            <button :disabled="showButtonSubmit" type="submit" id="btnrecuperarcontra"
                                class=" btn-success btn-block btn justify-content-center">Cambiar Contraseña</button>
                        </div>
                        <div class="col-12">
                            <a menu-option="iniciarSesion" hide-banners="true" v-on:click="this.$parent.changeMenuPrincipal"
                                class="btn btn-link col-form-label">Iniciar
                                sesi&oacute;n
                            </a>
                        </div>
                    </div>
                </center>
            </div>
        </form>
    </div>
</template>


<script>
import axios from 'axios';
export default {
    name: 'recuperarContrasena',
    data() {
        return {
            form: {
                correo: '',
                token_recuperar: '',
                contrasena_nueva: '',
                passwordConfirmation: ''
            }, validations: {
                isValidoEmail: false,
                isValidoToken: false,
                isValidoContrasena: false
            }, NoValidations: {
                isInvalidoEmail: false,
                isInvalidoToken: false,
                isInvalidoContrasena: false
            }
        }
    }, methods: {
        recuperarPassword() {
            this.$parent.loadingModalPrincipal("Verificando información...");
            axios.post(this.$endPointNewton + "/stock-push/registro-usuarios:modificarcontrasenarecuperar", this.form
            ).then((res) => {
                if (res.data.status === 'fail') {
                    this.$parent.ErrorModalPrincipal(res.message);
                } else {
                    this.cleanForm();
                    this.$parent.SuccessModalPrincipal("Contraseña cambiada exitosamente.", true, 'Continuar',);
                }
            }).catch((e) => {
                if (e.response.data.errors) {
                    this.$parent.ErrorModalPrincipal(e.response.data.errors.correo);
                } else {
                    this.$parent.ErrorModalPrincipal(e.response.data.message);
                }
            });
        }, validatingEmail(event) {
            if (this.$parent.validateEmailPrincipal(event.target.value)) {
                this.validations.isValidoEmail = true;
                this.NoValidations.isInvalidoEmail = false;
            } else {
                this.validations.isValidoEmail = false;
                this.NoValidations.isInvalidoEmail = true;

            }
        }, validatePasswords() {
            if (this.$parent.validatingEqualPasswordPrincipal(this.form.contrasena_nueva, this.form.passwordConfirmation)) {
                this.validations.isValidoContrasena = true;
                this.NoValidations.isInvalidoContrasena = false;
            } else {
                this.NoValidations.isInvalidoContrasena = true;
                this.validations.isValidoContrasena = false;
            }
        }, validateToken() {
            if (this.form.token_recuperar !== "") {
                this.validations.isValidoToken = true;
                this.NoValidations.isInvalidoToken = false;
            } else {
                this.validations.isValidoToken = false;
                this.NoValidations.isInvalidoToken = true;
            }
        }, cleanForm() {
            this.form.token_recuperar = "";
            this.form.correo = "";
            this.form.contrasena_nueva = "";
            this.form.passwordConfirmation = "";
            this.validations.isValidoEmail = false;
            this.validations.isValidoToken = false;
            this.validations.isValidoContrasena = false;
            this.NoValidations.isInvalidoEmail = false;
            this.NoValidations.isInvalidoToken = false;
            this.NoValidations.isInvalidoContrasena = false;
        }
    }, computed: {
        showButtonSubmit: function () {
            var validated = false;
            for (const prop in this.validations) {
                if (!this.validations[prop]) {
                    validated = true;
                }
            }
            return validated;
        }

    }
}

</script>