<template>
<center><h2>{{this.title.replace("_", " ")}}</h2></center>


</template>


<script>
export default {
    props: {
        title: {
            type: String,
            default: "Empresas"
        }
    }
};

</script>