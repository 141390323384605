<template>
    <div class="planes-container no-margin ">
        <div class="container-fluid">
            <div class="row pb-2">
                <div class="col-12 align-self-center text-center">
                    <h3 v-if="(this.showtitle)" class="title-seccion">Planes Publicitarios</h3>
                </div>
            </div>
            <div class="row justify-content-md-around justify-content-sm-center">
                <h3 class="text-center" v-if="(showmsj)">Por el momento no hay planes publicitarios disponibles</h3>
                <div class="col-md-3 col-12 planes" v-bind:key="data.index" v-for="data in planes">
                    <div class="card text-center" style="width: 18rem;">
                        <img v-bind:src="data.img" class="card-img-top " alt="">
                        <div v-bind:class="{ 'more-space': data.showContratar, 'card-body': !data.showContratar }">
                            <h5 class="subtitulo">{{ data.data.nombre_plan_publicitario }}</h5>
                            <p class="informacion">{{ data.data.descripcion }}</p>
                            <a class="col-md-10 col-12 btn btn-warning btn-planes" hide-banners="true"
                                menu-option="detallePlanPublicitario" v-bind:informacion="JSON.stringify(data)"
                                v-bind:id="data.id" v-on:click="showDetalles">Detalles </a>
                            <button v-on:click="contratarPlan" v-bind:id-contrado="data.data.id"
                                class="btn btn-success  margin-top-20 btn-planes" v-if="(data.showContratar)"
                                id="contratar-plan">Contratar plan</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'planes',
    props: {
        menuOpciones: {
            type: String,
            default: "detallePlan"
        },
        dataForComponent :{
            default: ""
        }    
    },
    data() {
        return {
            planes: [],
            
            fecha_hoy: "",
            showtitle: true,
            showmsj: false,
            showContratar: false,
        }
    }, methods: {
        getplanes() {
            var data = {
                'schema': 'planes'
            };
            var urlNamesFiles = this.$endPointNewton + "/stock-push/planes-img:consultarplanes";
            axios.post(urlNamesFiles, data).then((res) => {
                if (res.data.status === 'success') {
                    this.fecha_hoy = new Date(res.data.fecha_dia_hoy);
                    if (res.data.data.original.data.length > 0) {
                        this.getInfoFromPayLoad(res.data.data.original.data);
                    } else {
                        this.showmsj = this.planes.length <= 0 ? true : false;
                    }
                }
            }).catch(() => {
                this.$swal({
          title: 'Error',
          closeOnClickOutside: true,
          value: true,
          text : 'Ocurrió un error al intentar consultar los planes publicitarios.',
          visible: true,
          icon: "error",
          confirmButtonText: "Continuar",
        });

            });

        }, getInfoFromPayLoad(data) {
            var index = 0;
            data.forEach(element => {
                var fechaFin = new Date(element.payload.fecha_fin);
                if ((this.fecha_hoy < fechaFin) && element.payload.estatus) {
                    this.createComponentForPlan(element.payload.media.original.data.media, index, element.payload, element.id);
                }
                index++;
            });
            this.$swal.close();

        }, createComponentForPlan(media, index, payload, id) {
            axios.post(this.$endPointNewton + "/stock-push/planes:obtener", { 'media': media }, { responseType: 'blob' }).then((res) => {
                let reader = new FileReader();
                reader.readAsDataURL(res.data);
                reader.onload = () => {
                    const r =
                    {
                        "index": index,
                        "img": reader.result,
                        "data": payload,
                        "id": id,
                        "showContratar": this.showContratar
                    }
                    this.planes.push(r);
                }
            });
        }, showDetalles(event) {
            if (typeof sessionStorage.getItem('token') !== 'undefined' && sessionStorage.getItem('token') !== null) {
                this.$parent.$parent.$parent.loadPlanPublicitario(event);

            } else {
                this.$parent.$parent.loadPlanPublicitario(event);
            }

        }, contratarPlan(event) {
            if (typeof sessionStorage.getItem('token') !== 'undefined' && sessionStorage.getItem('token') !== null) {
                var idPlan = event.target.getAttribute("id-contrado");
                var data  = {"planId" : idPlan , "empresa" : this.dataForComponent};
                this.$parent.$parent.changeComponent("contratacion", data, "Contratación", "false");

            }
        }
    }, mounted() {
        if (typeof sessionStorage.getItem('token') !== 'undefined' && sessionStorage.getItem('token') !== null) {
            this.showtitle = false;
            this.showContratar = true;
        }
        this.getplanes();

    }
}
</script>
