<template>
    <div class="col-12 no-margin" name="banners">
        <b-carousel id="carousel-1" :interval="4000" controls indicators background="#ababab" padding="" img-width="1024"
            label-next="" label-prev="" style="text-shadow: 1px 1px 2px #333;">
            <b-carousel-slide v-bind:key="data.index" v-for="data in banners">
                <template #img>
                    <img v-bind:title="'imagen' + data.index" class="img-slider d-block w-100" width="1024" v-bind:src="data.img">
                </template>
            </b-carousel-slide>
        </b-carousel>

    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'banners',
    data() {
        return {
            banners: [],
            fecha_hoy: ""
        }
    }, methods: {
        getBanners() {
            var data = {
                'schema': 'banners'
            };
            var urlNamesFiles = this.$endPointNewton + "/stock-push/banners-img:consultarbanners";
            axios.post(urlNamesFiles, data).then((res) => {
                if (res.data.status !== 'success') {
                    this.$parent.$parent.$parent.ErrorModalGeneral('Ocurrió un error al consultar los banners.');
                } else {
                    this.$swal.close();
                    this.fecha_hoy = new Date(res.data.fecha_dia_hoy);
                    if (res.data.data.original.data.length > 0) {
                        this.getInfoFromPayLoad(res.data.data.original.data);
                    }
                }
            }).catch(() => {
                this.$parent.$parent.$parent.ErrorModalGeneral('Ocurrió un error al consultar los banners.');
            });


        }, getInfoFromPayLoad(data) {
            var index = 0;
            data.forEach(element => {
                var fechaFin = new Date(element.payload.fecha_fin);
                if ((this.fecha_hoy < fechaFin) && element.payload.estatus) {
                    this.createComponentForBanner(element.payload.media.original.data.media,index);
                }
                index++;
            });

        }, createComponentForBanner(media,index ) {
                axios.post(this.$endPointNewton + "/stock-push/banners:obtener", { 'media': media }, { responseType: 'blob' }).then((res) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(res.data);
                    reader.onload = () => {
                        const r = 
                        {
                            "index" : index,
                            "img" : reader.result
                        }
                        this.banners.push(r);
                }
                });
        }
    }, created() {
        this.getBanners();

    }
}

</script>
