<template>
  <div id="app" class="general">
    <indexApp name="indexApp" v-if="(!this.sesionIniciada)"></indexApp>
    <indexUsuario v-if="(this.sesionIniciada && this.typeSesion === 'cliente')"></indexUsuario>
    <indexAdmin v-if="(this.sesionIniciada && this.typeSesion === 'administrador')"></indexAdmin>
    <footerApp></footerApp>
  </div>
</template>

<script>

import axios from 'axios';
import indexApp from './components/commons/indexPrincipal.vue'
import footerApp from './components/commons/footer.vue'
import indexUsuario from './components/Usuario/indexUsuario.vue'
import indexAdmin from './components/administrador/indexAdmin.vue'
export default {
  name: 'principal',
  data() {
    return {
      sesionIniciada: false,
      typeSesion: "cliente",
    }
  },
  components: {
    footerApp,
    indexApp,
    indexUsuario,
    indexAdmin,
  }, methods: {
    ErrorModalGeneral(msg) {
      this.$swal({
        title: 'Error',
        closeOnClickOutside: true,
        value: true,
        text: msg,
        visible: true,
        icon: "error",
        confirmButtonText: "Continuar",
      });
    }, askQuestionModal(title, text, icon, showCancelButton, confirmButtonText, msjOk, msjOkTitle, sesion) {
      this.$swal({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: showCancelButton,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: confirmButtonText,
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: msjOkTitle,
            closeOnClickOutside: false,
            value: true,
            visible: true,
            icon: "success",
            confirmButtonText: msjOk,
          }).then(() => {
            if (sesion) {
              this.cerrarSesion();
            }
          });

        }
      })
    }, SuccessModalGeneral(title, btn, btnmessage, componentToLoad) {
      if (btnmessage === "" || typeof btnmessage == 'undefined') {

        btnmessage = 'Continuar';
      }
      if (title === "" || typeof title == 'undefined') {
        title = '';
      }
      if (btn && typeof componentToLoad != 'undefined') {
        this.$swal({
          title: title,
          closeOnClickOutside: false,
          value: true,
          visible: true,
          icon: "success",
          confirmButtonText: btnmessage,
        }).then(() => {
          this.verifySesion();
        });
      } else {
        this.$swal({
          title: title,
          closeOnClickOutside: false,
          value: true,
          visible: true,
          confirmButtonText: btnmessage,
          icon: "success",
        });
      }

    }, loadingModalGeneral(mensajeCarga) {
      this.$swal({
        allowEscapeKey: false,
        allowOutsideClick: false,
        title: mensajeCarga,
        didOpen: () => {
          this.$swal.showLoading();
        }
      });
      //VALIDACIONES PARA INPUTS        
    }, validateRFCGeneral(rfc, aceptarGenerico = true) {
      const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
      var validado = rfc.match(re);

      if (!validado)  //Coincide con el formato general del regex?
        return false;

      //Separar el dígito verificador del resto del RFC
      const digitoVerificador = validado.pop(),
        rfcSinDigito = validado.slice(1).join(''),
        len = rfcSinDigito.length,
        diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
        indice = len + 1;
      var suma, digitoEsperado;

      if (len == 12) {
        suma = 0;
      } else { suma = 481; }

      for (var i = 0; i < len; i++) {
        suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
        digitoEsperado = 11 - suma % 11;
        if (digitoEsperado == 11) {
          digitoEsperado = 0;

        } else if (digitoEsperado == 10) {
          digitoEsperado = "A";
        }
      }
      if ((digitoVerificador != digitoEsperado) && (!aceptarGenerico || rfcSinDigito + digitoVerificador != "XAXX010101000")) {
        return false;
      } else if (!aceptarGenerico && rfcSinDigito + digitoVerificador == "XEXX010101000") {
        return false;
      }
      return rfcSinDigito + digitoVerificador;

    }, validarTextoGeneral(e, acceptEmpty) {
      const key = e.keyCode || e.which;
      const tecla = String.fromCharCode(key).toLowerCase();
      const letras = " áéíóúabcdefghijklmnñopqrstuvwxyz";
      const especiales = "8-37-39-46-20".split("-");

      var tecla_especial = false;
      for (var i in especiales) {
        if (key == especiales[i]) {
          tecla_especial = true;
          break;
        }
      }

      if (letras.indexOf(tecla) == -1 && !tecla_especial) {
        return false;
      }
      if (e.target.value === "" && acceptEmpty) {
        return false;
      }
      return true;
    }, validateNumberGeneral(evt, emptyA) {
      if (emptyA && evt.target.value === "") {
        return true;
      }
      if (!emptyA && evt.target.value === "") {
        return false;
      }
      return /^([0-9])*$/.test(evt.target.value);
    }, validateEmailGeneral(email) {
      /* eslint-disable no-useless-escape */
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    }, validatingEqualPasswordGeneral(val1, val2) {
      return val1 === val2;
    }, cerrarSesionModal() {
      this.askQuestionModal("Cerrar sesi&oacute;n", "¿Estas seguro de que quieres cerrar la sesión ?", 'warning', true, "Si", "Continuar", "Haz cerrado sesi&oacute;n exitosamente", true);
    }, cerrarSesion() {
      sessionStorage.clear();
      this.sesionIniciada = this.verifySesion();
      this.typeSesion = "";
    }, verifySesion() {
      if (sessionStorage.getItem("rol") === 'cliente' && sessionStorage.getItem("token") !== null) {
        this.sesionIniciada = true;
        this.typeSesion = sessionStorage.getItem("rol");
      } else if (sessionStorage.getItem("rol") === 'administrador' && sessionStorage.getItem("token") !== null) {
        this.sesionIniciada = true;
        this.typeSesion = sessionStorage.getItem("rol");
      } else {
        this.sesionIniciada = false;
      }
    }, validateCelularGeneral(value) {
      if (value.length >= 10 && value.length <= 12) {
        return true;
      }
      return false;
    }, validateFechaMayorIgualAHoyGeneral(value) {
      var fechaActual = new Date().toISOString().substr(0, 10);
      var R = false;
      if (value > fechaActual) {
        R = true;
      } else if (fechaActual == value) {
        R = true;
      }
      return R;
    }, gettingImg(endPoint, name, msjError) {
      axios.post(endPoint, { 'media': name }, { responseType: 'blob' }).then((res) => {
        let reader = new FileReader();
        reader.readAsDataURL(res.data);
        reader.onload = () => {
          this.$swal.fire({
            imageUrl: reader.result,
            confirmButtonText: "Volver"
          })
        }
      }).catch(() => {
        this.$swal(
          'Error',
          msjError,
          'error');

      });
    }, validateURL(value) {
      var pattern = /^(http|https)\:\/\/[a-z0-9\.-]+\.[a-z]{2,4}/gi;
      return pattern.test(value);

    }, validateDecimalGeneral(value) {
      var regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
      return regexp.test(value);
    }, currencyVal(value) {
      if (!this.validateDecimalGeneral(value)) {
        return value;
      }
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });
      return formatter.format(value);
    }, numberFormat(value) {
      if (!this.validateDecimalGeneral(value)) {
        return value;
      }
      var formatter = new Intl.NumberFormat('en-US');
      return formatter.format(value);
    }, loadPlanPublicitario(event) {
      this.dataForComponent = event.target.getAttribute("informacion");
      var data = JSON.parse(this.dataForComponent);
      var title = data.data.nombre_plan_publicitario;
      this.$swal({
        title: "Plan " + title,
        showCancelButton: "yes",
        html: this.loadInfoPlan(data),
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Contratar ",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          this.verifySesion();
          if (this.sesionIniciada) {
            document.getElementById("contratar-plan").click();
          } else {
            this.$swal({
              title: "¿Deseas iniciar sesi&oacute;n ?",
              html: '<p class="">Es necesario iniciar sesi&oacute;n para contratar un plan publicitario.</p>',
              closeOnClickOutside: false,
              value: true,
              visible: true,
              icon: "info",
              confirmButtonText: "S&iacute; ",
              cancelButtonText: "No",
              showCancelButton: "yes",
            }).then((result) => {
              if (result.isConfirmed) {
                document.getElementById("li-sesion").click();
                document.getElementById("li-sesion").setAttribute("plan-contratar", data.id);
              }

            });
          }
        }
      })

    }, loadInfoPlan(data) {
      var info = '<div class="container container-descripcion-plan"><div class=" justify-content-center">';
      info += '<div class="col-12"><center><img src="' + data.img + '"><div class="div-icons-redes">';
      if (data.data.incluye_fb === "true" ? true : false) {
        info += '<i class="fa-brands fa-facebook"></i><i class="fa-brands fa-instagram"></i>';
      }
      if (data.data.incluye_google === "true" ? true : false) {
        if (data.data.incluye_fb === "true") {
          info += '<img class="fa-google " ';
        } else {
          info += '<img class="fa-google-sin-otros" ';

        }
      }
      info += '</div></center></div>';

      info += ' <div class="col-12 margin-top-bottom-20"><h4 class="title-descripcion">Descripci&oacute;n</h4></div>';
      info += '<div class="col-12"><p class="informacion">' + data.data.descripcion + '.</p>';
      info += '</div><div class="col-12 more-info-plan"><strong>Duraci&oacute;n del plan publicitario: </strong>';
      info += '<p> ' + data.data.dias + ' d&iacute;as </p>';
      info += '</div><div class="col-12 more-info-plan"><strong>Alcance de personas:   </strong>';
      info += '<p class="p-alcance"> ' + this.numberFormat(data.data.alcance) + '</p></div>';
      info += '<div class="col-12 more-info-plan"><strong>Costo: </strong><p class="p-costo"> ' + this.currencyVal(data.data.costo) + '</p></div>';
      info += '<div class="col-12 more-info-plan"><h4 class="title-descripcion">Publicidad en las siguientes plataformas</h4></div>';
      info += '<div class="col-12 more-info-plan-plataformas"><br>';
      if (data.data.incluye_fb === "true" ? true : false) {
        info += ' <p><i class="fa-brands fa-facebook"></i> - Facebook </p><br> <p ><i class="fa-brands fa-instagram"></i> - Instagram </p><br>';
      }
      if (data.data.incluye_google === "true" ? true : false) {
        info += '<p ><img  class="fa-google" > - Google </p>';
      }
      info += '</div></div></div>';
      return info;
    }, formatFecha(fecha) {
      const arrayFecha = fecha.split("-");
      let mes = parseInt(arrayFecha[1], 10);
      const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
      return arrayFecha[2] + " de " + meses[mes-1] + " del " + arrayFecha[0] + ".";
    }, formatFechaObject(fecha) {
      const arrayFecha = fecha.split("-");
      let mes = parseInt(arrayFecha[1], 10);
      var dia = arrayFecha[2].split('T')[0];
      const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
      return dia + " de " + meses[mes-1] + " del " + arrayFecha[0] + ".";
    }, formatFechaObjectRegister(fecha) {
      const arrayFecha = fecha.split("-");
      let mes = parseInt(arrayFecha[1], 10);
      var dia = arrayFecha[2].split(' ')[0];
      const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
      return dia + " de " + meses[mes-1] + " del " + arrayFecha[0] + ".";
    }
  }, mounted() {
    this.verifySesion();
  }
}


</script>
