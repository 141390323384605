
<template>
        <div>
                <div class="col-12">
                        <div class="row header options-container no-margin">
                                <ul class="nav nav-pills row no-margin">
                                        <li class="nav-item col-12 col-md-2  text-center center-text"><a
                                                        menu-option="planes" v-on:click="changeMenuPrincipal"
                                                        class="a-menu">Inicio</a></li>
                                        <li class="nav-item col-12 col-md-2  text-center center-text"><a
                                                        v-if="(!hideBanner)" menu-option="quienesSomos"
                                                        href="#quienesSomos" class=" a-menu">¿Qui&eacute;nes somos?</a>
                                        </li>
                                        <li class="nav-item col-12 col-md-2  text-center center-text"><a
                                                        v-if="(!hideBanner)" menu-option="comoFuncionamos"
                                                        href="#comoFuncionamos" class=" a-menu">¿C&oacute;mo
                                                        funcionamos?</a>
                                        </li>
                                        <li class="nav-item col-12 col-md-2  text-center center-text"><a
                                                        v-if="(!hideBanner)" menu-option="avisoPrivacidad"
                                                        href="#avisoPrivacidad" class=" a-menu">Aviso de privacidad</a>
                                        </li>
                                        <li class="nav-item col-12 col-md-2  text-center center-text"><a
                                                        v-if="(!hideBanner)" menu-option="contacto" href="#contacto"
                                                        class=" a-menu">Contacto</a></li>
                                        <li v-on:click="changeMenuPrincipal" id="li-sesion" plan-contratar="" menu-option="iniciarSesion"
                                                hide-banners="true"
                                                class=" btn-warning btn nav-item col-12 col-md-2  text-center center-text">
                                                Iniciar sesi&oacute;n / Registrarse
                                        </li>

                                </ul>
                        </div>
                </div>
                <div class="row container-img-logo">
                        <div class="col-md-5"></div>
                        <div class="col-12 col-md-2">
                                <img class="img-logo-principal" src="../../assets/img/header/logo.png"
                                        alt="imagen de stockpush">
                        </div>
                </div>
                <div class="container">
                        <Transition name="indexApp">
                                <banners name="banners" v-if="(!hideBanner)"></banners>
                        </Transition>
                        <br>
                        <div id="principalMenu">
                                <planes name="planes" v-if="(!hideBanner)"></planes>
                                <quienesSomos name="quienesSomos" v-if="(!hideBanner)"></quienesSomos>
                                <comoFuncionamos name="comoFuncionamos" v-if="(!hideBanner)"></comoFuncionamos>
                                <avisoPrivacidad name="avisoPrivacidad" v-if="(!hideBanner)"></avisoPrivacidad>
                                <contacto name="contacto" v-if="(!hideBanner)"></contacto>
                                        <iniciarSesion name="iniciarSesion"
                                                v-if="(hideBanner && this.nameC == 'iniciarSesion')"></iniciarSesion>
                                        <registro name="registro" v-if="(hideBanner && this.nameC == 'registro')">
                                        </registro>
                                        <recuperarContrasena name="recuperarContrasena"
                                                v-if="(hideBanner && this.nameC == 'recuperarContrasena')">
                                        </recuperarContrasena>
                                        <cambiarContrasena name="cambiarContrasena"
                                                v-if="(hideBanner && this.nameC == 'cambiarContrasena')"></cambiarContrasena>
                        </div>
                </div>

                <a v-if="(!hideBanner)" href="#" class="back-to-top" style=""><i class="fa-solid fa-arrow-up"></i></a>
        </div>
</template>
      
      
      
<script>
import planes from "../planesPublicitarios/planesPublicitarios.vue";
import quienesSomos from '../paginaPrincipal/quienesSomos.vue';
import comoFuncionamos from '../paginaPrincipal/comoFuncionamos.vue';
import avisoPrivacidad from '../paginaPrincipal/avisoPrivacidad.vue';
import contacto from '../paginaPrincipal/contacto.vue';
import banners from '../banners/banners.vue';
import iniciarSesion from '../paginaPrincipal/iniciarSesion.vue';
import registro from '../paginaPrincipal/registro.vue';
import recuperarContrasena from '../paginaPrincipal/recuperarContrasena.vue';
import cambiarContrasena  from '../paginaPrincipal/cambiarContrasena.vue';
export default {
        name: 'indexApp',
     data() {
                return {
                        hideBanner: false,
                        nameC: "iniciarSesion"
                }
        }, components: {
                banners,
                iniciarSesion,
                registro,
                recuperarContrasena,
                planes,
                quienesSomos,
                comoFuncionamos,
                avisoPrivacidad,
                contacto,
                cambiarContrasena
        },
        methods: {
                changeMenuPrincipal: function (event) {
                        if (typeof event.target.getAttribute('menu-option') !== 'undefined') {
                                this.changeTab(event.target.getAttribute('menu-option'));
                                this.hiddenBanners(event.target.getAttribute('hide-banners'));

                        } else {
                                console.log("el valor para llamar el componente es invalido.");
                        }
                }, changeTab(componentToLoad) {
                        this.nameC = componentToLoad;
                },
                hiddenBanners(hide) {
                        if (hide) {
                                this.hideBanner = hide;
                        } else {
                                this.hideBanner = false;
                        }

                }, ErrorModalPrincipal(msg) {
                        this.$parent.ErrorModalGeneral(msg);
                }, SuccessModalPrincipal(title, btn, btnmessage, url) {
                        this.$parent.SuccessModalGeneral(title, btn, btnmessage, url);
                }, loadingModalPrincipal(mensajeCarga) {
                        this.$parent.loadingModalGeneral(mensajeCarga);
                }, validateRFCPrincipal(rfc, aceptarGenerico = true) {
                        return this.$parent.validateRFCGeneral(rfc, aceptarGenerico);
                }, validarTextoPrincipal(e) {
                        return this.$parent.validarTextoGeneral(e);
                }, validateNumberPrincipal(e) {
                        return this.$parent.validateNumberGeneral(e);
                }, validateCelularPrincipal(e) {
                        return this.$parent.validateCelularGeneral(e);
                }, validateEmailPrincipal(email) {
                        /* eslint-disable no-useless-escape */
                        return this.$parent.validateEmailGeneral(email);
                }, validatingEqualPasswordPrincipal(val1, val2) {
                        return this.$parent.validatingEqualPasswordGeneral(val1, val2);
                }
        }
}
</script>


