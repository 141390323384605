<template>
    <div class="container ">
        <center>
            <h3 class="title-seccion">Crear una Cuenta</h3>
        </center>
        <form v-on:submit.prevent="registrar" method="post" class=" row justify-content-md-center">
            <div class="col-md-6 col-12 dialog-1  row justify-content-md-center"
                style="margin-left: 0px !important;margin-right: 0px !important;">
                <div class="form-group  col-12">
                    <div class="col-12">
                        <label for="nombre" class="col-form-label text-md-right">Nombre(s)<i
                                class="text-danger">*</i></label>
                    </div>
                    <div class="col-md-12">
                        <input v-model="form.nombre" v-on:keyup="validarTexto" class="form-control" id="nombre"
                            v-bind:class="{ 'input-correcto': validations.isValidoNombre, 'input-incorrecto': NoValidations.isInvalidoNombre }"
                            name="nombre" required>
                    </div>
                </div>
                <div class="form-group  col-12">
                    <div class="col-md-12">
                        <label for="apellidos" class="col-form-label text-md-right">Apellido(s)<i
                                class="text-danger">*</i></label>
                    </div>
                    <div class="col-md-12">
                        <input v-model="form.apellidos" v-on:keyup="validarTexto" type="text" class="form-control"
                            id="apellidos" name="apellidos"
                            v-bind:class="{ 'input-correcto': validations.isValidoApellido, 'input-incorrecto': NoValidations.isInvalidoApellido }"
                            required>
                    </div>
                </div>
                <div class="form-group  col-12">
                    <div class="col-md-12">
                        <label for="rfc" class="col-form-label text-md-right">RFC<i class="text-danger">*</i></label>
                    </div>
                    <div class="col-md-12">
                        <input v-model="form.rfc" v-on:input="validarRFCRegistro"
                            v-bind:class="{ 'input-correcto': validations.isValidoRFC, 'input-incorrecto': NoValidations.isInvalidoRFC }"
                            type="text" class="form-control" id="rfc" name="rfc" required>
                    </div>
                </div>
                <div class="form-group  col-12">
                    <div class="col-md-12">
                        <label for="telefono" class="col-form-label text-md-right">Tel&#233;fono<i
                                class="text-danger">*</i></label>
                    </div>
                    <div class="col-md-12">
                        <input v-model="form.telefono" maxlength="10" v-on:keyup="validateNumberPhone" type="text"
                            class="form-control" id="telefono" name="telefono"
                            v-bind:class="{ 'input-correcto': validations.isValidoNumber, 'input-incorrecto': NoValidations.isInvalidoNumber }"
                            required>
                    </div>
                </div>
                <div class="form-group  col-12">
                    <div class="col-md-12">
                        <label for="correo" class="col-form-label text-md-right">Correo Electr&oacute;nico<i
                                class="text-danger">*</i></label>
                    </div>
                    <div class="col-md-12">
                        <input v-model="form.correo" type="email" v-on:input="validatingEmail" class="form-control"
                            id="correo" name="correo"
                            v-bind:class="{ 'input-correcto': validations.isValidoEmail, 'input-incorrecto': NoValidations.isInvalidoEmail }"
                            required>
                    </div>
                </div>
                <div class="form-group col-12">
                    <div class="col-md-12">
                        <label for="password" class="col-form-label text-md-right">Contraseña<i
                                class="text-danger">*</i></label>
                    </div>
                    <div class="col-md-12">
                        <input v-model="form.contrasena" type="password" class="form-control" id="contrasena"
                            v-on:input="validatePasswords"
                            v-bind:class="{ 'input-correcto': validations.isValidoPassword, 'input-incorrecto': NoValidations.isInvalidoPassword }"
                            name="contrasena" required>
                    </div>
                </div>
                <div class="form-group col-12">
                    <div class="col-md-12">
                        <label for="password_confirmation" class="col-form-label text-md-right">Confirmar
                            contraseña<i class="text-danger">*</i></label>
                    </div>
                    <div class="col-md-12">
                        <input v-model="form.password_confirmation" type="password" class="form-control"
                            v-on:input="validatePasswords"
                            v-bind:class="{ 'input-correcto': validations.isValidoPassword, 'input-incorrecto': NoValidations.isInvalidoPassword }"
                            id="password_confirmation" name="password_confirmation" required>
                    </div>
                </div>
                <div><br></div>
                <div class=" row col-12 no-margin">
                    <div class="col-12 no-padding">
                        <input v-model="validations.check" class="form-check-input" type="checkbox" value=""
                            id="invalidCheck" required>
                        <label class="form-check-label" for="invalidCheck">
                            Acepto los términos y condiciones
                        </label>
                        <div class="invalid-feedback">
                            Debe estar de acuerdo antes de enviar.
                        </div>
                    </div>
                </div>
                <div><br></div>

                <div class="col-md-6 col-12">
                    <button :disabled="showButtonSubmit" type="submit"
                        class=" btn-success  btn-block btn justify-content-center">Registrarme</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'registro',
    data() {
        return {
            form: {
                nombre: '',
                apellidos: '',
                rfc: '',
                telefono: '',
                correo: '',
                contrasena: '',
                password_confirmation: "",
            },
            validations: {
                check: false,
                isValidoRFC: false,
                isValidoNombre: false,
                isValidoApellido: false,
                isValidoNumber: false,
                isValidoEmail: false,
                isValidoPassword: false,
            }, NoValidations: {
                isInvalidoRFC: false,
                isInvalidoNombre: false,
                isInvalidoApellido: false,
                isInvalidoNumber: false,
                isInvalidoEmail: false,
                isInvalidoPassword: false,
            },
        }
    }, methods: {
        registrar() {
            this.$parent.$parent.loadingModalGeneral("Registrando...");
            var nombre = this.form.nombre;
            var apellidos = this.form.apellidos;
            var rfc = this.form.rfc;
            var telefono = this.form.telefono;
            var correo = this.form.correo;
            var contrasena = this.form.contrasena;
            var data = { "nombre": nombre, "apellidos": apellidos, "rfc": rfc, "telefono": telefono, "correo": correo, "contrasena": contrasena, "roles": ["cliente"], "credenciales": ["@action:*", "@schema:*", "@catalogo:*", "@flujo:*", "@documento:*"] }
            axios.post(this.$endPointNewton + "/stock-push/registro-usuarios:actualizar", data
            ).then((res) => {

                if (res.data.status === 'fail') {
                    if (res.message) {
                        this.$parent.$parent.ErrorModalGeneral(res.message);
                    } else if (res.data.message) {
                        this.$parent.$parent.ErrorModalGeneral(res.data.message);
                    }
                } else if (res.status === 'fail') {
                    if (res.message) {
                        this.$parent.$parent.ErrorModalGeneral(res.message);
                    } else if (res.data.message) {
                        this.$parent.$parent.ErrorModalGeneral(res.data.message);
                    }
                } else {
                    this.cleanForm();
                    this.$parent.$parent.SuccessModalGeneral("Usuario Registrado exitosamente.", true, 'Continuar');
                }
            }).catch((e) => {
                var msj = 'Ocurrió un error al registrar';
                if (e.response.data.errors.correo) {
                    msj = e.response.data.errors.correo[0];
                }
                if (e.response.data.errors.rfc) {
                    msj = e.response.data.errors.rfc[0];
                }
                this.$parent.$parent.ErrorModalGeneral(msj);
            });
        }//VALIDACIONES PARA INPUTS
        , validarRFCRegistro() {
            var rfc = this.form.rfc.trim().toUpperCase();
            var rfcCorrecto = this.$parent.validateRFCPrincipal(rfc);
            if (rfcCorrecto) {
                this.validations.isValidoRFC = true;
                this.NoValidations.isInvalidoRFC = false;
            } else {
                this.validations.isValidoRFC = false;
                this.NoValidations.isInvalidoRFC = true;
            }
        }, validarTexto: function (event) {
            var input = event.target.getAttribute('id');
            var resultValid = this.$parent.validarTextoPrincipal(event);
            this.validatingResult(input, resultValid);
        }, validateNumberPhone: function (event) {
            this.$parent.validateNumberPrincipal(event);

            var validation = this.$parent.validateCelularPrincipal(event.target.value);
            this.validations.isValidoNumber = validation;
            this.NoValidations.isInvalidoNumber = !validation;
        }, validatingResult(input, result) {
            if (input === "apellidos") {
                this.validations.isValidoApellido = result;
                this.NoValidations.isInvalidoApellido = !result;
            } else if (input === "nombre") {
                this.validations.isValidoNombre = result;
                this.NoValidations.isInvalidoNombre = !result;
            }
        }, validatingEmail(event) {
            if (this.$parent.validateEmailPrincipal(event.target.value)) {
                this.validations.isValidoEmail = true;
                this.NoValidations.isInvalidoEmail = false;
            } else {
                this.validations.isValidoEmail = false;
                this.NoValidations.isInvalidoEmail = true;

            }
        }, validatePasswords() {
            if (this.$parent.validatingEqualPasswordPrincipal(this.form.contrasena, this.form.password_confirmation)) {
                this.validations.isValidoPassword = true;
                this.NoValidations.isInvalidoPassword = false;
            } else {
                this.NoValidations.isInvalidoPassword = true;
                this.validations.isValidoPassword = false;
            }
        }, cleanForm() {
            this.form.nombre = '';
            this.form.apellidos = '';
            this.form.rfc = '';
            this.form.telefono = '';
            this.form.correo = '';
            this.form.contrasena = '';
            this.form.password_confirmation = "";
            this.validations.check = false;
            this.validations.isValidoRFC = false;
            this.validations.isValidoNombre = false;
            this.validations.isValidoApellido = false;
            this.validations.isValidoNumber = false;
            this.validations.isValidoEmail = false;
            this.validations.isValidoPassword = false;
            this.NoValidations.isInvalidoRFC = false;
            this.NoValidations.isInvalidoNombre = false;
            this.NoValidations.isInvalidoApellido = false;
            this.NoValidations.isInvalidoNumber = false;
            this.NoValidations.isInvalidoEmail = false;
            this.NoValidations.isInvalidoPassword = false;
        }

    }, computed: {
        showButtonSubmit: function () {
            var validated = false;
            for (const prop in this.validations) {
                if (!this.validations[prop]) {
                    validated = true;
                }
            }
            return validated;
        }

    }
}

</script>